import "bootstrap/dist/css/bootstrap.min.css";
import "./styles.css";
import { useState, useEffect } from "react";
import { getAuth, signOut } from "firebase/auth";
import { Dropdown, Button, Container, Row, Col } from "react-bootstrap";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import moment from "moment";
import ProvinceArea from "./ProvinceArea.json";
import { LoginSignupModal } from "./LoginSignupModal";

export function LandingPage(props) {
  const [rows, setRows] = useState();
  const [areas, setAreas] = useState([]);
  const [province, setProvince] = useState("");

  const [worktype, setWorktype] = useState("");

  const [schooltype, setSchooltype] = useState("");

  const [modalShow, setModalShow] = useState(false);

  const handleloginUser = (eventKey, event) => {
    if (eventKey === "logout") {
      handlesignOut();
    } else if (
      eventKey === "member" &&
      props.registration.payment_status === 1
    ) {
      navigate("/schooledit");
    } else if (
      eventKey === "member" &&
      props.registration.payment_status === 0
    ) {
      navigate("/plan");
    }
  };

  const handleProvinceDropdownSelect = (eventKey, event) => {
    setRows(undefined);
    setProvince(eventKey);
  };

  const handleWorktypeDropdownSelect = (eventKey, event) => {
    setRows(undefined);
    setWorktype(eventKey);
  };

  const handleSchooltypeDropdownSelect = (eventKey, event) => {
    setRows(undefined);
    setSchooltype(eventKey);
  };

  const handleLoginclick = (event) => {
    setModalShow(true);
  };

  const navigate = useNavigate();
  const auth = getAuth();

  const handlesignOut = (event) => {
    signOut(auth).then(() => {
      props.onUserChange(undefined);
    });
  };

  const premium = (event) => {
    navigate("/plan");
  };

  const handleedit = (event) => {
    navigate("/schooledit");
  };

  //axios is the command to send query request to api
  useEffect(() => {
    console.log("useeffect", props.registration);
    // if (props.registration && props.registration.payment_status === 1) {
    if (rows === undefined) {
      axios
        .get(
          "https://krujob.herokuapp.com/api/krujob_sample?province=" +
          province +
          "&worktype=" +
          worktype +
          "&schooltype=" +
          schooltype
        )
        .then((res) => {
          console.log("rows", res.data);
          setRows(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    //    }
  }, [rows, props.registration, province, worktype, schooltype]);

  return (
    <div className="App">
      <div className="Topbar">
        <img src="img/Whitelogo_nobg.png" alt="logo" width="150"></img>
        {!props.user && (
          <Button variant="krujob" onClick={handleLoginclick}>
            Login
          </Button>
        )}

        {props.user && (
          <Dropdown onSelect={handleloginUser}>
            <Dropdown.Toggle variant="krujob" id="dropdown-basic">
              <img
                className="iconmargin"
                src="img/userman.svg"
                alt="icon"
              ></img>
              Hi,
              {props.registration &&
                props.registration.name_of_staff.split(" ")[0]}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item eventKey="member">
                <img
                  className="iconmargin"
                  src="img/quality.svg"
                  alt="icon"
                ></img>
                Member
              </Dropdown.Item>
              <Dropdown.Item eventKey="logout">
                <img className="iconmargin" src="img/exit.svg" alt="icon"></img>
                Logout
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )}

      </div>

      <div className="topbanner">
        <div className="Lp1">
          <Dropdown onSelect={handleProvinceDropdownSelect}>
            <Dropdown.Toggle variant="krujob" id="dropdown-basic">
              {province === "" ? "Province" : province}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {ProvinceArea.map((item, index) => (
                <Dropdown.Item key={index} eventKey={item.province}>
                  {item.province}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>

          <Dropdown onSelect={handleWorktypeDropdownSelect}>
            <Dropdown.Toggle variant="krujob" id="dropdown-basic">
              {worktype === "" ? "Worktype" : worktype}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item eventKey="Fulltime">fulltime</Dropdown.Item>
              <Dropdown.Item eventKey="Parttime">parttime</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown onSelect={handleSchooltypeDropdownSelect}>
            <Dropdown.Toggle variant="krujob" id="dropdown-basic">
              {schooltype === "" ? "Schooltype" : schooltype}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item eventKey="School">school</Dropdown.Item>
              <Dropdown.Item eventKey="Tutorschool">tutorschool</Dropdown.Item>
              <Dropdown.Item eventKey="language school">
                language school
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <div className="container">
        <p>the list of school who are currently hiring</p>
      </div>
      <div>{rows && <KrujobSampleList rows={rows} />}</div>

      <LoginSignupModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        onUserChange={props.onUserChange}
      />
    </div>
  );
}

function KrujobSampleList(props) {
  const navigate = useNavigate();
  const onRowClick = (row) => {
    navigate(`/jobdetail/${row.id}`, { state: row });
  };

  return props.rows.map((row) => (
    <Row key={row.id}
    onClick={() => onRowClick(row)}>
      
        {/* whole block  */}


        <Col xs={{span: 10, offset: 2 }} md={{ span: 3, offset: 1 }}>
          <img
             className="schooliconmargin"
            src={row.image_data}
            alt="schoolicon"
          ></img>
        </Col>
        
        <Col xs={{span: 10, offset: 2 }} md={{ span: 4, offset: 0 }}>
          <div className="schoolinfo1">
            <div className="schools">

              <div className="schooltitle">{row.title}</div>

              <div className="schoolinfo">
                {row.school_name}
                <br />
                {row.position}
                <br />
                {row.province}
                <br />
                {row.address}
              </div>
            </div>
          </div>
        </Col>
        <Col xs={{span: 10, offset: 2 }} md={{ span: 4, offset: 0 }}>
          <div className="schoolinfo2">
            <div className="schools">
              <div className="schoolinfo">
                {moment(row.updated_time).format("DD/MM/YY")}
                <br />
                {row.work_type}
                <br />
                {row.salary}
              </div>
            </div>
          </div>
        </Col>

    </Row>
  ));
}
