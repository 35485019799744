import "bootstrap/dist/css/bootstrap.min.css";
import "./styles.css";
import { useState } from "react";
import {
  getAuth,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signInWithEmailAndPassword,
  sendPasswordResetEmail
} from "firebase/auth";
import { Dropdown, Modal, Button, Form, Tabs, Tab } from "react-bootstrap";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ProvinceArea from "./ProvinceArea.json";

export function LoginSignupModal(props) {
  const [schoolname, setSchoolname] = useState("");
  const [province, setProvince] = useState("");
  const [address, setAddress] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password1, setPassword1] = useState("");
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const auth = getAuth();

  const signup = () => {
    if (schoolname.length < 3) {
      setMessage("school name is reqired.");
      return;
    } else if (province.length < 4) {
      setMessage("province is required.");
      return;
    } else if (address.length < 4) {
      setMessage("address is required.");
      return;
    } else if (phone.length < 9) {
      setMessage("phone is required.");
      return;
    } else if (email.length < 5) {
      setMessage("email is required.");
      return;
    } else if (password.length < 6) {
      setMessage("please set password more than 6 characters.");
      return;
    } else if (password1 !== password) {
      setMessage("please set the same password.");
      return;
    } else {
      setMessage("");
    }

    let newRow = {
      School_name: schoolname,
      Province: province,
      Address: address,
      name_of_staff: name,
      Phone: phone,
      Email: email,
      password: password,
      confirmpassword: password
    };

    axios
      .post("https://krujob.herokuapp.com/api/registration", newRow)
      .then(function (res) {
        console.log("registration", res);
        handleSignUpWithEmail();
      })
      .catch(function (err) {
        console.log("registration", err.message);
      });
  };
  const handleSignUpWithEmail = (event) => {
    createUserWithEmailAndPassword(auth, email, password)
      .then((credential) => {
        let user = credential.user;
        let actionCodeSettings = {
          url: `https://krujob.info/`
        };
        sendEmailVerification(user, actionCodeSettings)
          .then(function () {
            setMessage("Email sent.");
          })
          .catch(function (error) {
            setMessage(error.message);
          });
      })
      .catch((error) => {
        setMessage(error.message);
      });
  };

  const login = (event) => {
    if (email.length < 5 || password.length < 6) {
      setMessage("Please provide email and password.");
      return;
    }
    signInWithEmailAndPassword(auth, email, password)
      .then((credential) => {
        let token = credential.accessToken;
        let user = credential.user;
        //if (!user.emailVerified) {
        //setMessage("Email is not verified.");
        //return;
        //}
        console.log(user);
        props.onUserChange(user);
        props.onHide();
        navigate("/");
      })
      .catch((error) => {
        setMessage(error.message);
      });
  };

  const forgotPass = (event) => {
    event.preventDefault();
    if (email.length < 5) {
      setMessage("Please provide email address you registered.");
      return;
    }
    const auth = getAuth();
    let actionCodeSettings = {
      url: `https://krujob.info/?email=${email}`
    };
    sendPasswordResetEmail(auth, email, actionCodeSettings)
      .then(() => {
        setMessage("Password reset email sent!");
      })
      .catch((error) => {
        setMessage(error.message);
      });
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Login</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Tabs
          defaultActiveKey="login"
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          <Tab eventKey="login" title="Log in">
            <Form>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  value={email}
                  onChange={(event) => {
                    setEmail(event.target.value);
                  }}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Enter password"
                  value={password}
                  onChange={(event) => {
                    setPassword(event.target.value);
                  }}
                />
              </Form.Group>
              <p className="message">{message}</p>
              <div className="flex-container2 ">
                <Button variant="krujob" onClick={forgotPass}>
                  forgot password
                </Button>
              </div>
              <Button
                className="fullwidth"
                variant="krujobgreen"
                onClick={login}
              >
                login
              </Button>
            </Form>
          </Tab>
          <Tab eventKey="register" title="Register">
            <Form>
              <Form.Group className="mb-3" controlId="formBasicSchoolname">
                <Form.Label>school name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder=""
                  value={schoolname}
                  onChange={(event) => {
                    setSchoolname(event.target.value);
                  }}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>province</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  value={province}
                  onChange={(event) => {
                    setProvince(event.target.value);
                  }}
                >
                  <option>please choose province</option>
                  {ProvinceArea.map((item, index) => (
                    <option key={index} value={item.province}>
                      {item.province}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formAddress">
                <Form.Label>address</Form.Label>
                <Form.Control
                  type="text"
                  placeholder=""
                  value={address}
                  onChange={(event) => {
                    setAddress(event.target.value);
                  }}
                />
              </Form.Group>{" "}
              <Form.Group className="mb-3" controlId="formName">
                <Form.Label>name of school staff</Form.Label>
                <Form.Control
                  type="text"
                  placeholder=""
                  value={name}
                  onChange={(event) => {
                    setName(event.target.value);
                  }}
                />
              </Form.Group>{" "}
              <Form.Group className="mb-3" controlId="formBasicPhone">
                <Form.Label>phone</Form.Label>
                <Form.Control
                  type="number"
                  placeholder=""
                  value={phone}
                  onChange={(event) => {
                    setPhone(event.target.value);
                  }}
                />
              </Form.Group>{" "}
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="text"
                  placeholder=""
                  value={email}
                  onChange={(event) => {
                    setEmail(event.target.value);
                  }}
                />
              </Form.Group>{" "}
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder=""
                  value={password}
                  onChange={(event) => {
                    setPassword(event.target.value);
                  }}
                />
              </Form.Group>{" "}
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>confirm password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder=""
                  value={password1}
                  onChange={(event) => {
                    setPassword1(event.target.value);
                  }}
                />
              </Form.Group>
              <p className="message">{message}</p>
              <Button
                className="fullwidth"
                variant="krujobgreen"
                onClick={signup}
              >
                signup
              </Button>
            </Form>
          </Tab>
        </Tabs>
      </Modal.Body>
    </Modal>
  );
}
