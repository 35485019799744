import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getAuth,
  signInWithEmailAndPassword,
  sendPasswordResetEmail
} from "firebase/auth";

export function LoginPage(props) {
  // Declare a new state variable, which we'll call "count"
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const auth = getAuth();
  const login = (event) => {
    if (email.length < 5 || password.length < 5) {
      setMessage("Please provide email and password.");
      return;
    }
    signInWithEmailAndPassword(auth, email, password)
      .then((credential) => {
        let token = credential.accessToken;
        let user = credential.user;
        //if (!user.emailVerified) {
        //setMessage("Email is not verified.");
        //return;
        //}
        props.onUserChange(user);
        navigate("/");
      })
      .catch((error) => {
        setMessage(error.message);
      });
  };

  const back = () => {
    navigate("/");
  };
  const forgotPass = (event) => {
    event.preventDefault();
    if (email.length < 5) {
      setMessage("Please provide email address you registered.");
      return;
    }
    const auth = getAuth();
    let actionCodeSettings = {
      url: `https://ipbzhl.csb.app/?email=${email}`
    };
    sendPasswordResetEmail(auth, email, actionCodeSettings)
      .then(() => {
        setMessage("Password reset email sent!");
      })
      .catch((error) => {
        setMessage(error.message);
      });
  };

  return (
    <div>
      <b>email</b>
      <input
        type="text"
        name="email"
        value={email}
        onChange={(event) => {
          setEmail(event.target.value);
        }}
      />{" "}
      <br />
      <b>password</b>
      <input
        type="text"
        name="password"
        value={password}
        onChange={(event) => {
          setPassword(event.target.value);
        }}
      />
      <br />
      <button onClick={login}>login</button>
      <button onClick={back}>back</button>
      <p className="message">{message}</p>
      <p>
        in case you forget your password, plsease click{" "}
        <a href="#" onClick={forgotPass}>
          here
        </a>
        .
      </p>
    </div>
  );
}
