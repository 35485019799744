import "bootstrap/dist/css/bootstrap.min.css";
import "./styles.css";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";

export function PlanPage() {
  const [action, setAction] = useState("");
  const [rows, setRows] = useState();
  const navigate = useNavigate();
  const apply = (event) => {
    navigate("/schooledit");
  };
  const back = (event) => {
    navigate("/");
  };

  return (
    <div>
      <p className="planhead1">To person in charge of hiring</p>
      <p className="planhead2">the strong point of Krujob</p>
      <p className="planpointline">
        <span className="planpoint">point1</span>Can find with{" "}
        <span class="fontplan">cheapest price</span> more than other
      </p>
      <p className="planpointline">
        <span className="planpoint">point2</span>Can find{" "}
        <span class="fontplan">as fast as possible </span>and help your school
      </p>
      <p className="planpointline">
        <span className="planpoint">point3</span>
        <span class="fontplan">A lot of access </span>in this page and it will
        guarantee your success
      </p>
      <p className="planstrongpointline">
        <span className="planstrongpoint">Strong point1:</span>{" "}
        <span className="fontplan">you no need to pay a lot of fee.</span> Just
        you can start from 1,580THB for 2month
      </p>
      <p className="planstrongpointline">
        <span className="planstrongpoint">Strong point2:</span> Hiring is always
        urgent. We will support you to find best teacher{" "}
        <span class="fontplan">as soon as possible</span>
      </p>
      <p className="planstrongpointline">
        <span className="planstrongpoint">Strong point3:</span>{" "}
        <span class="fontplan">A lot of enthusiastic instructors</span> come to
        this page because it is linking each subject topic page.
      </p>
      <p className="planprice">1,580THB/2month</p>
      <div className="flex-container1">
        <Button variant="krujob" onClick={back}>
          back
        </Button>
        <Button variant="krujobgreen" onClick={apply}>
          apply
        </Button>
        　　　　
      </div>
    </div>
  );
}
