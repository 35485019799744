import "bootstrap/dist/css/bootstrap.min.css";
import "./styles.css";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";

export function CompleteEditing() {
  const [action, setAction] = useState("");
  const [rows, setRows] = useState();
  const navigate = useNavigate();
  const goback = (event) => {
    navigate("/");
  };

  return (
    <div>
      <p>Thank you for editing post</p>
      <p >
        thank you for updating your school info. Your updated information is validated already.
      </p>
      <div className="flex-container4 ">
        <Button className="mediumwidth" variant="krujobgreen" onClick={goback}>
          back
        </Button>
      </div>
    </div>
  );
}
