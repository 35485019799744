const firebaseConfig = {
  apiKey: "AIzaSyCU5QdawvJZg8iwie3rkv6fmzeNwyb4X1Y",
  authDomain: "krujob-541a1.firebaseapp.com",
  projectId: "krujob-541a1",
  storageBucket: "krujob-541a1.appspot.com",
  messagingSenderId: "153168949315",
  appId: "1:153168949315:web:e71424891fc5e505e1a4f6",
  measurementId: "G-5KMNCH89LR"
};

export default firebaseConfig;
