import "./styles.css";
import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { propTypes } from "react-bootstrap/esm/Image";
import { Form, Button, Row, Col } from "react-bootstrap";
import ProvinceArea from "./ProvinceArea.json";
import { getAuth } from "firebase/auth";

export function SchoolEditPage(props) {
  // Declare a new state variable, which we'll call "count"
  const [id, setId] = useState("");
  const [schoolname, setSchoolname] = useState("");
  const [province, setProvince] = useState("");
  const [address, setAddress] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [position, setPosition] = useState("");
  const [title, setTitle] = useState("");
  const [schooltype, setSchooltype] = useState("");
  const [workingtype, setWorkingtype] = useState("");
  const [area, setArea] = useState("");
  const [salary, setSalary] = useState("");
  const [welfare, setWelfare] = useState("");
  const [workingtime, setWorkingtime] = useState("");
  const [schoolintro, setSchoolintro] = useState("");
  const [teachinggrade, setTeachinggrade] = useState("");
  const [jobdetail, setJobdetail] = useState("");
  const [qualification, setQualification] = useState("");
  const [whyhiring, setWhyhiring] = useState("");
  const [whoyouwant, setWhoyouwant] = useState("");
  const [howtoapply, setHowtoapply] = useState("");
  const [contactname, setContactname] = useState("");
  const [imagefile, setImagefile] = useState();
  const imagefileURL = useMemo(() => {
    return imagefile ? URL.createObjectURL(imagefile) : undefined;
  }, [imagefile]);
  const [imagedata, setImagedata] = useState();

  const navigate = useNavigate();
  const [message, setMessage] = useState("");

  const auth = getAuth();

  useEffect(() => {
    // Get data once while form is empty.    }
    if (props.registration) {
      console.log("registration", props.registration);
      setSchoolname(props.registration.school_name);
      setProvince(props.registration.province);
      setAddress(props.registration.address);
      setName(props.registration.name_of_staff);
      setPhone(props.registration.phone);
      setEmail(props.registration.email);
    }

    if (props.user.email) {
      console.log(props.user.email);
      axios
        .get(
          "https://krujob.herokuapp.com/api/krujob_sample?email_address=" +
          props.user.email
        )
        .then((res) => {
          let row = res.data[0];
          console.log("row", row);
          if (row) {
            setId(row.id);
            setSchoolname(row.school_name);
            setProvince(row.province);
            setAddress(row.address);
            setEmail(row.email_address);
            setName(row.name_of_staff);
            setPhone(row.phone);
            setTitle(row.title);
            setPosition(row.position);
            setSchooltype(row.school_type);
            setWorkingtype(row.work_type);
            setArea(row.school_area);
            setSalary(row.salary);
            setWelfare(row.welfare);
            setWorkingtime(row.working_time);
            setSchoolintro(row.school_introduction);
            setTeachinggrade(row.teaching_grade);
            setJobdetail(row.job_detail);
            setQualification(row.qualification);
            setWhyhiring(row.why_now_hiring);
            setHowtoapply(row.how_to_apply);
            setContactname(row.contact_name);
            setWhoyouwant(row.who_you_want);
            setImagedata(row.image_data);
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  }, [props.user.email, props.registration]);

  //  const handleSchooltypeChange = (name, checked) => {
  //    console.log("handleSchooltypeChange name=", name, "checked=", checked);
  //    if (checked) {
  //      if (schooltype.indexOf(name) < 0) {
  //         setSchooltype((prev) => [...prev, name]);
  //       }
  //     } else {
  //       if (schooltype.indexOf(name) >= 0) {
  //         setSchooltype((prev) => prev.filter((v) => v !== name));
  //       }
  //     }
  //   };

  const save = async () => {
    if (email.length < 7) {
      setMessage("email address is reqired.");
      return;
    } else if (schoolname.length < 4) {
      setMessage("school name is required.");
      return;
    } else if (province.length < 4) {
      setMessage("province is required.");
      return;
    } else if (address.length < 4) {
      setMessage("address is required.");
      return;
    } else if (phone.length < 9) {
      setMessage("phone is required.");
      return;
    } else if (email.length < 5) {
      setMessage("email is required.");
      return;
    } else if (position.length < 3) {
      setMessage("position is required.");
      return;
    } else if (workingtime.length < 5) {
      setMessage("working time is required");
      return;
    } else if (salary.length < 3) {
      setMessage("salary is required.");
      return;
    } else if (qualification.length < 5) {
      setMessage("qualification is required.");
      return;
    } else if (howtoapply.length < 5) {
      setMessage("please add how to apply.");
      return;
    } else {
      setMessage("");
    }

    if (id) {
      let fd = new FormData();
      fd.append("School_name", schoolname);
      fd.append("Province", province);
      fd.append("Address", address);
      fd.append("name_of_staff", name);
      fd.append("phone", phone);
      fd.append("email_address", email);
      fd.append("title", title);
      fd.append("position", position);
      fd.append("school_type", schooltype);
      fd.append("work_type", workingtype);
      fd.append("school_area", area);
      fd.append("salary", salary);
      fd.append("welfare", welfare);
      fd.append("working_time", workingtime);
      fd.append("teaching_grade", teachinggrade);
      fd.append("school_introduction", schoolintro);
      fd.append("job_detail", jobdetail);
      fd.append("qualification", qualification);
      fd.append("why_now_hiring", whyhiring);
      fd.append("who_you_want", whoyouwant);
      fd.append("how_to_apply", howtoapply);
      fd.append("contact_name", contactname);
      fd.append("image_data", imagefile);
      console.log(imagefile);

      const idToken = await auth.currentUser.getIdToken(true);
      const config = {
        headers: {
          Authorization: "Bearer " + idToken,
          "Content-Type": "multipart/form-data"
        }
      };

      console.log(id);

      axios
        .put("https://krujob.herokuapp.com/api/krujob_sample/" + id, fd, config)

        .then(function (res) {
          navigate("/editedpost");

          //console.log(res);
        })
        .catch(function (err) {
          console.log(err);
          setMessage(err.message);
        });
    } else {
      let fd = new FormData();
      fd.append("School_name", schoolname);
      fd.append("Province", province);
      fd.append("Address", address);
      fd.append("name_of_staff", name);
      fd.append("phone", phone);
      fd.append("email_address", email);
      fd.append("title", title);
      fd.append("position", position);
      fd.append("school_type", schooltype);
      fd.append("work_type", workingtype);
      fd.append("school_area", area);
      fd.append("salary", salary);
      fd.append("welfare", welfare);
      fd.append("working_time", workingtime);
      fd.append("teaching_grade", teachinggrade);
      fd.append("school_introduction", schoolintro);
      fd.append("job_detail", jobdetail);
      fd.append("qualification", qualification);
      fd.append("why_now_hiring", whyhiring);
      fd.append("who_you_want", whoyouwant);
      fd.append("how_to_apply", howtoapply);
      fd.append("contact_name", contactname);
      fd.append("image_data", imagefile);

      const idToken = await auth.currentUser.getIdToken(true);
      const config = {
        headers: {
          Authorization: "Bearer " + idToken,
          "Content-Type": "multipart/form-data"
        }
      };

      axios
        .post("https://krujob.herokuapp.com/api/krujob_sample", fd, config)
        .then(function (response) {
          navigate("/completepost");
          window.open("https://forms.gle/Uyg1ikE7awPkezRS6", "_blank");
          console.log(response);
        })
        .catch(function (err) {
          console.log(err);
          setMessage(err.message);
        });
    }
  };

  const back = () => {
    navigate("/");
  };

  return (
    <Form>
      <Row>
      <Col md= "6" xs = "12">
      <Form.Group className="mb-3" controlId="formBasicSchoolname">
        <Form.Label>SchoolName</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter school name"
          value={schoolname}
          onChange={(event) => {
            setSchoolname(event.target.value);
          }}
        /> 
      </Form.Group>
            </Col>
       <Col md = "6" xs ="12">     
      <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Label>province</Form.Label>
        <Form.Select
          aria-label="Default select example"
          value={province}
          onChange={(event) => {
            setProvince(event.target.value);
          }}
        >
          {ProvinceArea.map((item, index) => (
            <option key={index} value={item.province}>
              {item.province}
            </option>
          ))}
        </Form.Select>
      </Form.Group>
      </Col>
      </Row>

      <Form.Group className="mb-3" controlId="formBasicAddress">
        <Form.Label>Address</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter Address"
          as="textarea"
          value={address}
          onChange={(event) => {
            setAddress(event.target.value);
          }}
        />
      
      </Form.Group>
      <Row>
        <Col md = "6" xs ="12">
      <Form.Group className="mb-3" controlId="formBasicName">
        <Form.Label>Name</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter Name"
          value={name}
          onChange={(event) => {
            setName(event.target.value);
          }}
        />
      </Form.Group>
      </Col>
      <Col md = "6" xs ="12">
      <Form.Group className="mb-3" controlId="formBasicPhone">
        <Form.Label>Phone</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter Phone"
          value={phone}
          onChange={(event) => {
            setPhone(event.target.value);
          }}
        />
      </Form.Group>
      </Col>
      </Row>

      <Form.Group className="mb-3" controlId="formBasicEmailaddress">
        <Form.Label>Email</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter Email Address"
          value={email}
          onChange={(event) => {
            setEmail(event.target.value);
          }}
        />
      </Form.Group>
      <p>Hiring info</p>
      <Form.Group className="mb-3" controlId="formBasicTitle">
        <Form.Label>title</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter Title"
          value={title}
          onChange={(event) => {
            setTitle(event.target.value);
          }}
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId="formBasicPosition">
        <Form.Label>position</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter Position"
          value={position}
          onChange={(event) => {
            setPosition(event.target.value);
          }}
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId="formBasicSchooltype">
        <Form.Label>schooltype</Form.Label>
        <div className="mb-3">
          <Form.Check
            type="radio"
            inline
            label="Daytime school"
            checked={schooltype === "Daytimeschool"}
            onChange={(event) => setSchooltype("Daytimeschool")}
          />
          <Form.Check
            type="radio"
            inline
            label="Tutor school"
            checked={schooltype === "Tutorschool"}
            onChange={(event) => setSchooltype("Tutorschool")}
          />
          <Form.Check
            type="radio"
            inline
            label="Other"
            checked={schooltype === "Other"}
            onChange={(event) => setSchooltype("Other")}
          />
        </div>
      </Form.Group>

      <Row>
       <Col md = "6" xs ="12"> 
      <Form.Group className="mb-3" controlId="formBasicWorkingtype">
        <Form.Label>workingtype</Form.Label>
        <Form.Select
          aria-label="Default select example"
          value={workingtype}
          onChange={(event) => {
            setWorkingtype(event.target.value);
          }}
        >
          <option>Select Workingtype</option>
          <option value="Fulltime">Full time</option>
          <option value="Parttime">Part time</option>
        </Form.Select>
      </Form.Group>
      </Col>
      <Col md = "6" xs ="12"> 

      <Form.Group className="mb-3" controlId="formBasicSalary">
        <Form.Label>salary</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter Salary"
          value={salary}
          onChange={(event) => {
            setSalary(event.target.value);
          }}
        />
      </Form.Group>

      </Col> 
      </Row>

      <Form.Group className="mb-3" controlId="formBasicWelfare">
        <Form.Label>welfare</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter Welfare"
          value={welfare}
          as="textarea"
          onChange={(event) => {
            setWelfare(event.target.value);
          }}
        />
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicWorkingtime">
        <Form.Label>workingtime</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter Wworking time"
          value={workingtime}
          onChange={(event) => {
            setWorkingtime(event.target.value);
          }}
        />
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicArea">
        <Form.Label>area</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter Area"
          value={area}
          onChange={(event) => {
            setArea(event.target.value);
          }}
        />
      </Form.Group>




      <Form.Group className="mb-3" controlId="formBasicSchoolintroduction">
        <Form.Label>schoolintro</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter School introduction"
          value={schoolintro}
          as="textarea"
          onChange={(event) => {
            setSchoolintro(event.target.value);
          }}
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId="formBasicTeachinggrade">
        <Form.Label>teachinggrade</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter teaching grade"
          value={teachinggrade}
          onChange={(event) => {
            setTeachinggrade(event.target.value);
          }}
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId="formBasicJobdetail">
        <Form.Label>jobdetail</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter Job detail"
          value={jobdetail}
          as="textarea"
          onChange={(event) => {
            setJobdetail(event.target.value);
          }}
        />
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicQualification">
        <Form.Label>qualification</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter Qualification"
          value={qualification}
          as="textarea"
          onChange={(event) => {
            setQualification(event.target.value);
          }}
        />
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicWhyhiring">
        <Form.Label>Why now hiring</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter Why now hiring"
          value={whyhiring}
          as="textarea"
          onChange={(event) => {
            setWhyhiring(event.target.value);
          }}
        />
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicWhoyouwant">
        <Form.Label>Who you want to work with</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter who you want to work with"
          value={whoyouwant}
          as="textarea"
          onChange={(event) => {
            setWhoyouwant(event.target.value);
          }}
        />
      </Form.Group>

      <Row>
        <Col md = "6" xs ="12">
      <Form.Group className="mb-3" controlId="formBasicHowtoapply">
        <Form.Label>howtoapply</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter How to apply"
          value={howtoapply}
          onChange={(event) => {
            setHowtoapply(event.target.value);
          }}
        />
      </Form.Group>
      </Col>
      <Col md = "6" xs ="12">
      <Form.Group className="mb-3" controlId="formBasicContactname">
        <Form.Label>contactname</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter Contact name"
          value={contactname}
          onChange={(event) => {
            setContactname(event.target.value);
          }}
        />
      </Form.Group>
      </Col>
      </Row>
      <Form.Group className="mb-3" controlId="formBasicImagefile">
        <Form.Label>image_data</Form.Label>
        <Form.Control
          type="file"
          placeholder="upload image"
          onChange={(event) => {
            setImagefile(event.target.files[0]);
          }}
        />
        <img
          className="image-preview"
          src={imagefile ? imagefileURL : imagedata}
          alt=""
        />
      </Form.Group>

      <p className="message">{message}</p>

      <div className="flex-container1">
        <Button variant="krujob" onClick={back}>
          back
        </Button>
        <Button variant="krujobgreen" onClick={save}>
          save
        </Button>
      </div>
    </Form>
  );
}
