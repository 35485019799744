import "bootstrap/dist/css/bootstrap.min.css";
import "./styles.css";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";

export function CompletePost() {
  const [action, setAction] = useState("");
  const [rows, setRows] = useState();
  const navigate = useNavigate();
  const goback = (event) => {
    navigate("/");
  };

  return (
    <div>
      <p className="completepost1">Thank you for posting job hiring info</p>
      <p className="completepost2">
        After you upload slip on the form, we will validate your payment and
        enable your account to premium, so please wait untill that.we will
        validate within 2 business days
      </p>
      <div className="flex-container4 ">
        <Button className="mediumwidth" variant="krujobgreen" onClick={goback}>
          back
        </Button>
      </div>{" "}
      　　　　
    </div>
  );
}
