import "./styles.css";
import { useState, useEffect } from "react";
import { LoginPage } from "./LoginPage";
import { RegisterPage } from "./RegisterPage";
import { LandingPage } from "./LandingPage";
import { SchoolEditPage } from "./SchoolEditPage";
import { JobdetailPage } from "./JobdetailPage";
import { Empty } from "./Empty";
import { PlanPage } from "./PlanPage";
import { CompletePost } from "./CompletePost";
import { Routes, Route, Outlet, Link, useNavigate } from "react-router-dom";
import config from "./FirebaseConfig";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

import axios from "axios";
import { CompleteEditing } from "./CompleteEditing";

const app = initializeApp(config);
const auth = getAuth(app);

export default function App() {
  const loadToken = () => {
    const tokenString = localStorage.getItem("token");
    if (tokenString) {
      const userToken = JSON.parse(tokenString);
      return userToken;
    } else {
      return undefined;
    }
  };

  const saveToken = (userToken) => {
    if (userToken) {
      console.log(userToken);
      localStorage.setItem("token", JSON.stringify(userToken));
      setUser(userToken);
      setRegistration(undefined);
    } else {
      localStorage.removeItem("token");
      setUser(undefined);
      setRegistration(undefined);
    }
  };
  const [user, setUser] = useState(loadToken());
  const [registration, setRegistration] = useState();
  // const navigate = useNavigate();
  useEffect(() => {
    // Get data once while form is empty.    }
    if (user) {
      console.log(user.email);
      axios
        .get(
          "https://krujob.herokuapp.com/api/registration?Email=" + user.email
        )
        .then((res) => {
          console.log(res.data);
          setRegistration(res.data[0]);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [user]);

  if (user) {
    return (
      <div className="App1">
        <Routes>
          <Route
            path="/"
            element={
              <LandingPage
                user={user}
                registration={registration}
                onUserChange={saveToken}
              />
            }
          />
          <Route path="/plan" element={<PlanPage />} />
          <Route
            path="/schooledit"
            element={
              <SchoolEditPage
                user={user}
                registration={registration}
                onUserChange={saveToken}
              />
            }
          />
          <Route path="/jobdetail/:id" element={<JobdetailPage />} />
          <Route path="/completepost" element={<CompletePost />} />
          <Route path="/editedpost" element={<CompleteEditing />} />
        </Routes>
      </div>
    );
  } else {
    return (
      <div className="App1">
        <Routes>
          <Route
            path="/login"
            element={<LoginPage onUserChange={saveToken} />}
          />
          <Route path="/reg" element={<RegisterPage />} />
          <Route
            path="/"
            element={
              <LandingPage
                user={user}
                registration={registration}
                onUserChange={saveToken}
              />
            }
          />
          <Route path="/jobdetail/:id" element={<JobdetailPage />} />
          <Route path="/" element={<LandingPage />} />
        </Routes>
      </div>
    );
  }
}
