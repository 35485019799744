import "bootstrap/dist/css/bootstrap.min.css";
import "./styles.css";
import { useState, useEffect } from "react";
import axios from "axios";
import { useLocation, Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { useNavigate,useParams } from "react-router-dom";

export function JobdetailPage(props) {
  const { id } = useParams();
  const [row, setRow] = useState();
  const navigate = useNavigate();
  const back = () => {
 
    navigate("/");
  };

  useEffect(() => {
   console.log(id);
    if (row === undefined) {
      axios
        .get(
          "https://krujob.herokuapp.com/api/krujob_sample?id=" +id
        )
        .then((res) => {
          console.log("rows", res.data);
          setRow(res.data[0]);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [row]);



  // Workaround: if user refresh jobdetail page, state will be null and it will show only "Back" link.
  if (row === undefined) {
    return (
      <div>
        <Link to="/">"Back"</Link>
      </div>
    );
  }
  
  return (
    <div className="flex-container0">
      <div className="flex-container">
        <div className="schoolinfo1">
          <div className="schools">
            <img
              className="schooliconmargin"
              src={row.image_data}
              alt="schoolicon"
            ></img>
          </div>
        </div>
        <div className="schoolinfo3">
          <b>School Name</b>
          <p>{row.School_name}</p>
          <b>Title</b>
          <p>{row.title}</p>
          <b>Position</b>
          <p>{row.position}</p>
          <b>Province</b>
          <p>{row.Province}</p>
          <b>Address</b>
          <p>{row.Address}</p>
          <b>Jobtype</b>
          <p>{row.work_type}</p>
          <b>Salary</b>
          <p>{row.salary}</p>
        </div>
      </div>
      <div className="container">
        <Button variant="krujobgreen" onClick={back}>
          back
        </Button>
      </div>
    </div>
  
  );

}
