import "./styles.css";
import React, { useState } from "react";
import {
  getAuth,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signInWithEmailAndPassword
} from "firebase/auth";

import { useNavigate } from "react-router-dom";
import axios from "axios";

export function RegisterPage() {
  // Declare a new state variable, which we'll call "count"
  const [schoolname, setSchoolname] = useState("");
  const [province, setProvince] = useState("");
  const [address, setAddress] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const auth = getAuth();

  const signup = () => {
    if (schoolname.length < 3) {
      setMessage("school name is reqired.");
      return;
    } else if (province.length < 4) {
      setMessage("province is required.");
      return;
    } else if (address.length < 4) {
      setMessage("address is required.");
      return;
    } else if (phone.length < 9) {
      setMessage("phone is required.");
      return;
    } else if (email.length < 5) {
      setMessage("email is required.");
      return;
    } else if (password.length < 5) {
      setMessage("please set password more than 5 characters.");
      return;
    } else {
      setMessage("");
    }

    let newRow = {
      School_name: schoolname,
      Province: province,
      Address: address,
      name_of_staff: name,
      Phone: phone,
      Email: email,
      password: password,
      confirmpassword: password
    };

    axios
      .post("https://krujob.herokuapp.com/api/registration", newRow)
      .then(function (res) {
        console.log("registration", res);
        handleSignUpWithEmail();
      })
      .catch(function (err) {
        console.log("registration", err);
      });
  };

  const handleSignUpWithEmail = (event) => {
    createUserWithEmailAndPassword(auth, email, password)
      .then((credential) => {
        let user = credential.user;
        let actionCodeSettings = {
          url: `https://ipbzhl.csb.app/`
        };
        sendEmailVerification(user, actionCodeSettings)
          .then(function () {
            setMessage("Email sent.");
          })
          .catch(function (error) {
            setMessage(error.message);
          });
      })
      .catch((error) => {
        setMessage(error.message);
      });
  };

  const back = () => {
    navigate("/");
  };

  return (
    <div>
      <b>schoolname</b>
      <input
        type="text"
        name="schoolname"
        value={schoolname}
        onChange={(event) => {
          setSchoolname(event.target.value);
        }}
      />{" "}
      <br />
      <b>province</b>
      <input
        type="text"
        name="province"
        value={province}
        onChange={(event) => {
          setProvince(event.target.value);
        }}
      />{" "}
      <br />
      <b>address</b>
      <input
        type="text"
        name="address"
        value={address}
        onChange={(event) => {
          setAddress(event.target.value);
        }}
      />{" "}
      <br />
      <b>name</b>
      <input
        type="text"
        name="name"
        value={name}
        onChange={(event) => {
          setName(event.target.value);
        }}
      />{" "}
      <br />
      <b>phone</b>
      <input
        type="text"
        name="phone"
        value={phone}
        onChange={(event) => {
          setPhone(event.target.value);
        }}
      />{" "}
      <br />
      <b>emailaddress</b>
      <input
        type="text"
        name="email"
        value={email}
        onChange={(event) => {
          setEmail(event.target.value);
        }}
      />{" "}
      <br />
      <b>password</b>
      <input
        type="text"
        name="password"
        value={password}
        onChange={(event) => {
          setPassword(event.target.value);
        }}
      />{" "}
      <br />
      <button onClick={signup}>signup</button>
      <button onClick={back}>back</button>
      <p className="message">{message}</p>
    </div>
  );
}
